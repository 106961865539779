import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    lng: 'ru',
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
    },
    // language resources
    resources: {
      en: {
        translation: require("./locales/en.json"),
      },
      ru: {
        translation: require("./locales/ru.json"),
      },
      kz: {
        translation: require("./locales/kz.json"),
      },
    }
  });

export default i18n;