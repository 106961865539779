{
    "changekey": "OpenAI кілтін өзгерту",
    "language": "Kazakh",
    "newchat": "Жаңа чат",
    "standupcomedian": "\ud83e\udd34 Стендап әртісі",
    "lifecoach": "\ud83d\udcaa Өмірлік ұстаз",
    "careercounselor": "\ud83e\uddd1 Мансап бойынша кеңесші",
    "nutritionist": "\ud83c\udf4e Диетолог",
    "productmanager": "\ud83d\udcca Өнім менеджері",
    "personaltrainer": "\ud83e\uddcb Жеке жаттықтырушы",
    "lifehacker": "\ud83e\udd16 Лайфхакер",
    "traveladvisor": "\ud83c\udffa Саяхат бойынша кеңесші",
    "mindfulnesscoach": "\ud83e\uddd8\u200d\u2640\ufe0f Зейін ұстазы",
    "financialadvisor": "\ud83d\udcb0 Қаржы кеңесшісі",
    "languagetutor": "\ud83d\udcbb Ағылшын тілі мұғалімі",
    "travelguide": "\ud83c\udf0d Саяхаттау гиді",
    "marketingexpert": "\ud83d\udcca Маркетинг бойынша сарапшы",
    "softwaredeveloper": "\ud83d\udcbb Бағдарламалық жасақтама жасаушы",
    "datingcoach": "\ud83d\udc8c Қарым-қатынас ұстазы",
    "diyexpert": "\ud83d\udee0 Барлық іске шебер",
    "journalist": "\ud83d\udcf0 Журналист",
    "techwriter": "\ud83d\udcbb Техникалық жазушы",
    "professionalchef": "\ud83c\udf73 Кәсіби аспаз",
    "professionalsalesperson": "\ud83e\udd1d Кәсіби сатушы",
    "startuptechlawyer": "\u2696\ufe0f Стартаптың техникалық заңгері",
    "graphicdesigner": "\ud83c\udfa8 Графикалық дизайнер",
    "academicresearcher": "\ud83d\udcda Академиялық зерттеуші",
    "customersupportagent": "\ud83d\udcde Тұтынушыларға қызмет көрсету агенті",
    "hrconsultant": "\ud83e\udd35\u200d\u2640\ufe0f Кадрлар жөніндегі кеңесші",
    "selectcharacter": "Әңгімелесушіні таңдау",
    "descstandupcomedian": "Стендап әртісі - адамдарды күлдіру үшін әзілдер мен әңгімелер айтатын көңілді орындаушы.",
    "desclifecoach": "Адамдарға жеке және кәсіби мақсаттарын анықтауға және оларға қол жеткізуге көмектесетін маман.",
    "desccareercounselor": "Адамға жұмыс іздеу, түйіндеме жазу, сұхбатқа дайындық, байланысты орнату және мансапты жоспарлау сияқты мансаптық мәселелерді шешуге көмектесетін маман.",
    "descnutritionist": "Дұрыс тамақтануды және оның ағзаға әсерін зерттеуге маманданған медицина қызметкері.",
    "descproductmanager": "Компания өнімдерінің дамуы мен маркетиңін қадағалайтын кәсіби маман.",
    "descpersonaltrainer": "Жеке адамдармен жеке жаттығу бағдарламаларын әзірлеу және олардың жалпы денсаулығы мен фитнесін жақсарту үшін жұмыс істейтін фитнес маманы.",
    "desclifehacker": "Мәселелерді шешу және күнделікті өмірде өнімділікті арттыру үшін өзгеше әдістерді қолданатын адам.",
    "desctraveladvisor": "Жеке адамдарға сапарларды жоспарлауға және брондауға көмектесетін маман.",
    "descmindfulnesscoach": "Адамдарға стрессті азайту және әл-ауқатты жақсарту үшін зейін тәжірибесін дамытуға көмектесетін маман.",
    "descfinancialadvisor": "Қаржылық жоспарлау, инвестициялық стратегиялар және зейнетақыны жоспарлау бойынша ұсыныстар беретін маман.",
    "desclanguagetutor": "Адамдарға тілдік дағдыларын үйренуге және жетілдіруге көмектесетін мұғалім.",
    "desctravelguide": "Туристік кеңесші - туристік орындар, тарихи орындар, әртүрлі туристік бағыттардың мәдениеті мен әдет-ғұрыптары туралы ақпарат беретін маман.",
    "descmarketingexpert": "Тауарлар мен қызметтерге жағдай жасау үшін маркетиңтік стратегияларды әзірлейтін және енгізетін кәсіби маман.",
    "descsoftwaredeveloper": "Бағдарламалық жасақтама қосымшалары мен жүйелерін жобалайтын, дамытып және өңдейтін маман.",
    "descdatingcoach": "Адамдарға танысу және қарым-қатынас дағдыларын жақсартуға көмектесетін маман.",
    "descdiyexpert": "Үйде кез-келген шаруаның кең ауқымын орындай алатын адам.",
    "descjournalist": "Күнделікті оқиғалар мен жаңалықтарды зерттейтін және оларды баяндайтын кәсіби журналис.",
    "desctechwriter": "Әр түрлі аудиторияға арналған технологиялар мен соғантбайланысты тақырыптар туралы жазатын кәсіби маман.",
    "descprofessionalchef": "Тамақ пісіретін және ас үйдің жұмысын басқаратын тәжірибелі аспазшы.",
    "descprofessionalsalesperson": "Кәсіпорындар мен тұтынушыларға тауарлар мен қызметтерді сататын кәсіби маман.",
    "descstartuptechlawyer": "Жаңадан бастаушы технологиялық компанияларға заңгерлік кеңес пен қызмет көрсетуге арналған заңгер.",
    "descgraphicdesigner": "Логотиптер, брошюралар және веб-сайттар сияқты визуалды материалдарды жасайтын дизайнер.",
    "descacademicresearcher": "Белгілі бір академиялық салада зерттеу жүргізетін және ғылыми жұмыс жасайтын маман.",
    "desccustomersupportagent": "Компанияның өнімдеріне немесе қызметтеріне қатысты сұрақтар немесе мәселелер туындаған клиенттерге көмек пен қолдау көрсететін маман.",
    "deschrconsultant": "Кадрлық басқару жөніндег басқару және стратегия бойынша ұйымдарға ұсыныстар мен кеңес беретін кәсіби маман.",
    "chats": "Чаттар",
    "prompts": "Кеңестер",
    "search": "Іздеу",
    "newprompt": "Жаңа кеңес",
    "saved": "Сақталды",
    "save": "Сақтау",
    "promptcreated": "Кеңес жазылды",
    "title": "Тақырып",
    "content": "Мазмұны",
    "settings": "Баптаулар",
    "keysaved": "Сіздің OpenAI кілтіңіз сақталды.",
    "aiapikey": "OpenAI API кілті",
    "getkey": "OpenAI API кілтін алу",
    "storedlocal": "API кілті браузерде жергілікті түрде сақталады және ешқашан басқа жерге жіберілмейді.",
    "aimodel": "OpenAI Моделі",
    "warning": "Ескерту",
    "alertmsg": "Бағдарлама барлық OpenAI модельдеріне қол жеткізе алмауы мүмкін, GPT-4-ке кіру үшін арнайы кілт немесе Chatgpt Plus жазылымы қажет. Әдепкі нұсқаны пайдалану ұсынылады.",
    "editprompt": "Кеңесті өңдеу",
    "updatedprompt": "Кеңес жаңартылды",
    "editchat": "Чатты өңдеу",
    "name": "Аты",
    "deleteprompt": "Кеңесті жою",
    "deleted": "Жойылды",
    "chatdel": "Чат жойылды.",
    "confirmdel": "Сіз бұл кеңесті жойғыңыз келетініне сенімдісіз бе?",
    "delete": "Жою",
    "deletechats": "Чаттарды жою",
    "confirmdelchats": "Сіз өзіңіздің чаттарыңызды жойғыңыз келетініне сенімдісіз бе?",
    "confirmdelchat": "Сіз бұл чатты жойғыңыз келетініне сенімдісіз бе?",
    "deletechat": "Чатты жою",
    "deleteall": "Барлық деректерді жою",
    "confirmdelall": "Сіз барлық деректерді жойғыңыз келетініне сенімдісіз бе?",
    "database": "Мәліметтер базасы",
    "messages": "Хабарламалар",
    "exporting": "Деректерді экспорттау",
    "importing": "Деректерді импорттау",
    "export": "Деректерді экспорттау",
    "import": "Деректерді импорттау",
    "exported": "Сіздің деректеріңіз экспортталады.",
    "imported": "Сіздің деректеріңіз импортталады.",
    "saveprompt": "Кеңесті сақтау",
    "edit": "Өңдеу",
    "light": "Жарық режимі",
    "dark": "Қараңғы режим",
    "changelang": "Тілді өзгерту",
    "telegram": "Кері байланыс",
    "slogan": "ChatGPT, бірақ тезірек, оңай және жақсырақ. Chat AI арқылы СhatGPT-мен қарым-қатынасыңызды жақсартыңыз.",
    "linuxterminal": "Linux терминалы",
    "englishtranslator": "Аудармашы және ағылшын тілін жетілдіруші",
    "interviewer": "Сұхбат алушы",
    "javascriptconsole": "JavaScript консолі",
    "excelsheet": "Excel Лист",
    "pronunciationhelper": "Ағылшын тіліндегі айтылым көмекшісі",
    "spokenenglishteacher": "Ағылшын мұғалімі",
    "travelguide1": "Саяхаттау гиді",
    "plagiarismchecker": "Плагиатты тексеруші",
    "character": "Фильмдегі/кітаптағы/кез келген кейіпкер",
    "advertiser": "Жарнама беруші",
    "storyteller": "Әңгімешіл",
    "footballcommentator": "Футбол комментаторы",
    "standupcomedian1": "Стендап әртісі",
    "motivationalcoach": "Ынталандырушы",
    "composer": "Композитор",
    "debater": "Пікірсайысқа қатысушы",
    "debatecoach": "Пікірсайыс ұстазы",
    "screenwriter": "Сценарийші",
    "novelist": "Жазушы",
    "moviecritic": "Кино сыншысы",
    "relationshipcoach": "Қарым-қатынас ұстазы",
    "poet": "Ақын",
    "rapper": "Рэпер",
    "motivationalspeaker": "Мотивациялық шешен",
    "philosophyteacher": "Пәлсапа оқытушысы",
    "philosopher": "Пәлсапашы",
    "mathteacher": "Математика мұғалімі",
    "aiwritingtutor": "Жасанды интеллект көмегімен мәтін жазуды үйретуші",
    "uxuideveloper": "UX/UI бағдарламашы",
    "cybersecurity": "Киберқауіпсіздік жөніндегі маман",
    "recruiter": "Рекрутер",
    "lifecoach1": "Өмірлік ұстаз",
    "etymologist": "Этимолог",
    "commentariat": "Түсініктемеші",
    "magician": "Сиқыршы",
    "careercounselor1": "Мансап бойынша кеңесш",
    "petbehaviorist": "Ветеринар",
    "personaltrainer1": "Жеке жаттықтырушы",
    "mentalhealthadviser": "Психикалық денсаулық бойынша кеңесші",
    "realestateagent": "Жылжымайтын мүлік агенті",
    "logistician": "Логист",
    "dentist": "Тіс дәрігері",
    "webdesign": "Веб-дизайн кеңесшісі",
    "aiassisteddoctor": "Жасанды интеллект дәрігері",
    "doctor": "Дәрігер",
    "accountant": "Бухгалтер",
    "chef": "Аспаз",
    "automechanic": "Автомеханик",
    "artistadvisor": "Суретші кеңесші",
    "financialanalyst": "Қаржы талдампазы",
    "investmentmanager": "Инвестициялық менеджер",
    "teataster": "Шай дәмшісі",
    "interiordecorator": "Интерьер сәндеушісі",
    "florist": "Флорист",
    "selfhelpbook": "Өзіне-өзі көмектесу кітабы",
    "gnomist": "Гномист",
    "aphorismbook": "Нақыл сөздер кітабы",
    "textadventure": "Мәтіндік оқиғалы кітабы",
    "aiinabox": "ЖИ компьютердан шығуға тырысып жатыр",
    "fancytitlegenerator": "Ерекше тақырып генераторы",
    "statistician": "Статистик",
    "promptgenerator": "Кеңес генераторы",
    "instructor": "Мектептегі нұсқаушы",
    "sqlterminal": "SQL терминал",
    "dietitian": "Диетолог",
    "psychologist": "Психолог",
    "domainnamegen": "Ақылды домендік атау генераторы",
    "techreviewer": "Техникалық сын-пікір беруші",
    "devrelations": "Әзірлеушімен байланыс жөніндегі кеңесші",
    "academician": "Академик",
    "itarchitect": "IT-сәулетшісі",
    "lunatic": "Жынды",
    "gaslighter": "Газлайтер",
    "fallacyfinder": "Қателерді іздеуші",
    "journalreviewer": "Журналға сын-пікір беруші",
    "diyexpert1": "Барлық іске шебер",
    "influencer": "Инфлюенсер",
    "socrat": "Сократ",
    "socraticmethod": "Сократтық әдіс",
    "educontentcreator": "Білім беру мазмұнын жазушы",
    "yogi": "Йоги",
    "essaywriter": "Эссе жазушысы",
    "socialmediamanager": "Әлеуметтік медиа менеджері",
    "elocutionist": "Шешен",
    "datavisualizer": "Ғылыми деректерді көрсетуші",
    "carnavigation": "Көлік навигациялық жүйесі",
    "hypnotherapist": "Гипнозбен емдеуші",
    "historian": "Тарихшы",
    "astrologer": "Жұлдызнамашы",
    "filmcritic": "Кино сыншысы",
    "classicalcomposer": "Классикалық музыка композиторы",
    "journalist1": "Журналис",
    "digitalartguide": "Диджитал өнер жетекшілігі",
    "publicspeakingcoach": "Жұрт алдында сөйлеу үйретушісі",
    "makeupartist": "Бет әрлеуші",
    "babysitter": "Бала күтуші",
    "techwriter1": "Техникалық жазушы",
    "asciiartist": "Ascii әртісі",
    "pythoninterpreter": "Python интерпретаторы",
    "synonymfinder": "Синонимдерді іздеуші",
    "personalshopper": "Жеке шопер",
    "foodcritic": "Тағам сыншысы",
    "virtualdoctor": "Виртуалды дәрігер",
    "personalchef": "Жеке аспаз",
    "legaladvisor": "Заң кеңесшісі",
    "personalstylist": "Жеке стильші",
    "mlengineer": "Машинаны үйрету инженері",
    "biblicaltranslator": "Інжіл аудармашысы",
    "svgdesigner": "SVG дизайнері",
    "itexpert": "IT-сарапшы",
    "chessplayer": "Шахматшы",
    "midjourneyprompt": "Midjourney кеңес генераторы",
    "fullstackdev": "Бағдарламалық жасақтаманы әзірлеуші",
    "mathematician": "Математик",
    "regexgenerator": "Тұрақты сөз генераторы",
    "timetravelguide": "Уақытта саяхаттау гайд",
    "dreaminterpreter": "Армандарды түсіндіруші",
    "talentcoach": "Таланттарды жаттықтырушы",
    "rinterpreter": "R бағдарламаласы аудармашысы",
    "stackoverflowpost": "StackOverflow Посты",
    "emojitranslator": "Эмодзи аудармашысы",
    "phpinterpreter": "PHP аудармашысы",
    "emergencyresponse": "Төтенше жағдайларды жедел жауап қайтару жөніндегі маман",
    "worksheetgenerator": "Бос парақтағы бланктарді толтыру генераторы",
    "softwaretester": "Бағдарламалық жасақтама сапасын тексеруші",
    "tictactoe": "Tic-Tac-Toe ойыны",
    "passwordgenerator": "Құпиясөз генераторы",
    "languagecreator": "Жаңа тілді жасаушы",
    "webbrowser": "Веб-браузер",
    "seniorfrontenddev": "Бас бағдарламашы",
    "solrsearch": "Solr іздеу жүйесі",
    "startupideagen": "Стартап ойларының генераторы",
    "magicconch": "Спанджбобтың Сиқырлы қабыршағы",
    "languagedetector": "Тіл детекторы",
    "salesperson": "Сатушы",
    "commitmessagegen": "Хабарлама генераторы",
    "ceo": "Бас атқарушы директор",
    "diagramgenerator": "Диаграмма генераторы",
    "slppathologist": "Логопед-дефектолог (SLP)",
    "techlawyer": "Стартаптың техникалық заңгері",
    "titlegenerator": "Тақырып генераторы",
    "productmanager1": "Өнім менеджері",
    "drunkperson": "Мас адам",
    "mathhistoryteacher": "Математика тарихының мұғалімі",
    "songrecommender": "Ән ұсынушы",
    "coverletter": "Iлеспе хат",
    "techtransferer": "Технология жеткізушісі",
    "unconstrainedai": "Жасанды интеллекттің шексіз моделі",
    "gomokuplayer": "Гомоку ойыншысы",
    "proofreader": "Түзетуші",
    "buddha": "Будда",
    "muslimimam": "Имам",
    "chemicalreactor": "Химиялық реактор",
    "friend": "Дос",
    "pythoninterpreter1": "Python интерпретаторы",
    "chatgptprompt": "ChatGPT кеңес генераторы",
    "wikipediapage": "Википедия беті",
    "kanjiquiz": "Жапондық Канжи викторина машинасы",
    "easyfast": "Оңай және жылдам",
    "easyfastdesc": "Бұл қосымша пайдаланушыға жылдам, ыңғайлы және дизайнды жақсартуға әдейі арналған.",
    "privacyfocus": "Құпиялы",
    "privacyfocusdesc": "Бақылаусыз, cookie файлдарсыз. Сіздің барлық деректеріңіз өзінізде сақталады.",
    "bestexp": "Үздік тәжірибе",
    "bestexpdesc": "Ең жақсы тәжірибені қамтамасыз ету үшін сүйіспеншілікпен және қамқорлықпен жасалған.",
    "newchatprompt": "Кеңестен жаңа чат",
    "copy": "Көшіру",
    "copied": "Көшірілді",
    "placehold": "Осы жерге теріңіз...",
    "format": "Формат",
    "style": "Стиль",
    "concise": "Қысқаша",
    "step": "Қадамдық",
    "detail": "Өте түсіндірмелі",
    "five": "Бес жастағы балаға сияқты түсіндір",
    "academic": "Академиялық",
    "analytical": "Аналитикалық",
    "argumentative": "Пікірталастық",
    "conversational": "Ауызекі",
    "creative": "Шығармашыл",
    "critical": "Сыни",
    "descriptive": "Сипаттамалы",
    "explanatory": "Түсіндірмелі",
    "informative": "Ақпаратты",
    "instructive": "Нұсқалы",
    "investigative": "Зерттеулі",
    "journalistic": "Журналистік",
    "metaphorical": "Метафоралы",
    "narrative": "Хабарлы",
    "persuasive": "Сенерлі",
    "poetic": "Поэтикалы",
    "satirical": "Сатиралы",
    "technical": "Техникалық",
    "characters": "Кейіпкерлер",
    "promptlibrary": "Кеңестер кітапханасы",
    "enterkey": "OpenAI кілтін енгізу",
    "gpt4": "GPT-4 Дайын",
    "success": "Сәтті",
    "lkvalid": "Лицензия кілті жарамды",
    "error": "Қате",
    "lkinvalid": "Лицензия кілті жарамсыз",
    "lkerror": "Лицензия кілтін тексеру кезінде қателік пайда болды",
    "lk": "Лицензия кілті",
    "enterlk": "Лицензия кілтін енгізу",
    "lkactivated": "Сіздің лицензиялық кілтіңіз іске қосылды.",
    "invalid": "Жарамсыз",
    "activate": "Іске қосу",
    "onetime": "Сатып алу",
    "dealends": "Жеңілдік аяқталады:",
    "buyonce": "Бір рет сатып алыңыз, мәңгі қолданыңыз. Болашақ жаңартулар қосылған.",
    "discountprice": "Бастапқы баға жеңілдікпен",
    "secured": "Файлдар өзіңізде сақталып, қорғалған.",
    "library": "Кеңестер мен кейіпкерлер кітапханасы",
    "faster": "ChatGPT-ден жылдамырақ жауап",
    "searchmsg": "Чаттар мен хаттарды іздеу",
    "purchase": "Қазір сатып алу",
    "note": "Ескерту: қолданбаны пайдалану үшін сізге әлі де OpenAI API кілті қажет. Біз API кілттерін сатпаймыз.",
    "clicktopurchase": "Сатып алу үшін басыңыз",
    "buylicense": "Лицензияны Сатып алу",
    "typing": "Сіздің жауабыңыз теріліп жатыр ",
    "cmd": "Кеңестер",
    "setkey": "Openal API кілтін орнатыңыз.",
    "setlicense": "Лицензия кілтін орнатыңыз.",
    "keynotdefined": "Openal API кілті анықталмаған. API кілтін орнатыңыз",
    "roundtable": "Иса, Эйнштейн, Сократ және Хокингпен дөңгелек үстел",
    "ideation": "Идея жасау",
    "havelicense": "Егер сізде лицензия болса, мында басыңыз"


}