import { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Avatar,
  Text,
  Paper,
  useMantineTheme,
  Stack,
  Group,
  TextInput,
  ActionIcon,
} from "@mantine/core";
import { IconChevronRight, IconSearch, IconX, IconStarFilled, IconStar } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useDisclosure,useMediaQuery } from "@mantine/hooks";
import { premadePrompts } from "../utils/constants";

interface PremadePrompt {
  label: string;
  value: string;
  special?: boolean;
  emoji: string;
}

interface PromptsModalProps {
  onSelect: (selectedValue: string) => void;
}

export function PromptsModal({ onSelect }: PromptsModalProps) {
  const { t, i18n } = useTranslation();
  const theme = useMantineTheme();
  const [opened, { open, close }] = useDisclosure();
  const [selectedPrompt, setSelectedPrompt] = useState(t('promptlibrary'));
  const [search, setSearch] = useState("");
  const isMobile = useMediaQuery("(max-width: 50em)");

  useEffect(() => {
    setSelectedPrompt(t("promptlibrary"));
}, [i18n.language]);

  function handleSelect(prompt: PremadePrompt) {
    const selectedPrompt = t(prompt.label);
    setSelectedPrompt(selectedPrompt);
    onSelect(t(prompt.value));
    close();
  }

  function handleCancel() {
    onSelect("");
    setSelectedPrompt(t("promptlibrary"));
    close();
  }

  return (
    <>
      <Button variant="gradient" gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }} onClick={open}>
        {selectedPrompt || t("promptlibrary")}
      </Button>
      <Modal 
        opened={opened} 
        onClose={handleCancel} 
        size={610}
        fullScreen={isMobile}
        radius="md"
        title={t("cmd")}
        overlayProps={{
          color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
        >
        <TextInput
          radius="lg"
          placeholder={t('search') || "Поиск"}
          value={search}
          
          onChange={(event) =>
            setSearch(event.currentTarget.value.toLowerCase())
          }
          sx={{ paddingInline: 4, marginBottom: 18 }}
          icon={<IconSearch opacity={0.8} size={20} />}
          rightSection={
            !!search && (
              <ActionIcon onClick={() => setSearch("")}>
                <IconX opacity={0.5} size={20} />{" "}
              </ActionIcon>
            )
          }
        />
        <Stack>
        {premadePrompts
            .filter((prompt) => t(prompt.label).toLowerCase().includes(search))
            .sort((a, b) => (a.special === b.special ? 0 : a.special ? -1 : 1))
            .map((prompt) => (
            <Paper
              key={prompt.label}
              onClick={() => handleSelect(prompt)}
              style={{ 
                cursor: "pointer",
                backgroundColor: prompt.special ? theme.colors.yellow[1] : "transparent",
              }}
              shadow="lg"
              p="lg"
              withBorder
            >
              <Group>
                <Avatar color="teal" radius={isMobile ? "md" : "xl"} size={isMobile ? "md" : "lg"}>{prompt.emoji}</Avatar>
                <Text 
                  fz={isMobile ? "sm" : "lg"} 
                  weight={500} 
                  style={{
                    marginLeft: isMobile ? theme.spacing.sm : 0,
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    maxWidth: "50%", 
                    }}> 
                  {t(prompt.label)}
                </Text>
                {prompt.special && (
                  <Text
                    fz={isMobile ? "sm" : "md"}
                    style={{
                      marginLeft: theme.spacing.sm,
                      fontWeight: "bold",
                      color: theme.colors.yellow[9],
                    }}
                  >
                    Popular!
                  </Text>
                  
                )}
                <div style={{ flexGrow: 1 }}></div>
                {!isMobile && (
                <Button
                  variant="gradient"
                  gradient={{ from: "teal", to: "blue", deg: 60 }}
                  radius="md"
                >
                  <IconChevronRight />
                </Button>
              )}
              </Group>
              
            </Paper>
          ))}
        </Stack>
      </Modal>
    </>
  );
}
