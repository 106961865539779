import React, { createContext, useState, useContext } from "react";

type SelectedPromptContextType = {
  selectedPromptContent: string | null;
  setSelectedPromptContent: (content: string | null) => void;
};

const SelectedPromptContext = createContext<SelectedPromptContextType | undefined>(undefined);

type SelectedPromptProviderProps = {
  children: React.ReactNode;
};

export const SelectedPromptProvider: React.FC<SelectedPromptProviderProps> = ({ children }) => {
  const [selectedPromptContent, setSelectedPromptContent] = useState<string | null>(null);

  return (
    <SelectedPromptContext.Provider value={{ selectedPromptContent, setSelectedPromptContent }}>
      {children}
    </SelectedPromptContext.Provider>
  );
};

export const useSelectedPrompt = () => {
  const context = useContext(SelectedPromptContext);
  if (!context) {
    throw new Error("useSelectedPrompt must be used within a SelectedPromptProvider");
  }
  return context;
};
