import {
  Badge,
  Button,
  Center,
  Container,
  Group,
  SimpleGrid,
  Text,
  ThemeIcon,
} from "@mantine/core";
import {
  IconCloudDownload,
  IconBolt,
  IconKey,
  IconLock,
  IconNorthStar,
} from "@tabler/icons-react";
import { useLiveQuery } from "dexie-react-hooks";
import { Logo } from "../components/Logo";
import { SettingsModal } from "../components/SettingsModal";
import { FeaturesModal } from "../components/FeaturesModal";
import { db } from "../db";
import { useTranslation } from "react-i18next";

export function IndexRoute() {
  const settings = useLiveQuery(() => db.settings.get("general"));
  const { openAiApiKey } = settings ?? {};
  const { t, i18n } = useTranslation();

  return (
    <>
      <Center py="xl" sx={{ height: "100%" }}>
        <Container size="sm">
          <Badge mb="sm">{t("gpt4")}</Badge>
          <Text>
            <Logo style={{ height:60, maxWidth: 210 }} />
          </Text>
          <Text mt={2} size="lg">
          {t('slogan')}
          </Text>
          <SimpleGrid
            mt={50}
            cols={3}
            spacing={30}
            breakpoints={[{ maxWidth: "md", cols: 1 }]}
          >
            {features.map((feature) => (
              <div key={feature.title}>
                <ThemeIcon variant="outline" size={50} radius={50}>
                  <feature.icon size={26} stroke={1.5} />
                </ThemeIcon>
                <Text mt="sm" mb={7}>
                  {t(feature.title)}
                </Text>
                <Text size="sm" color="dimmed" sx={{ lineHeight: 1.6 }}>
                  {t(feature.description)}
                </Text>
              </div>
            ))}
          </SimpleGrid>
          <Group mt={50}>
            <SettingsModal>
              <Button
                size="md"
                variant={openAiApiKey ? "light" : "filled"}
                leftIcon={<IconKey size={20} />}
              >
                {openAiApiKey ? t('changekey') : t('enterkey')}
              </Button>
            </SettingsModal>
            <FeaturesModal></FeaturesModal>
          </Group>
        </Container>
      </Center>
    </>
  );
}

const features = [
  {
    icon: IconBolt,
    title: "easyfast",
    description:
      "easyfastdesc",
  },
  {
    icon: IconLock,
    title: "privacyfocus",
    description:
      "privacyfocusdesc",
  },
  {
    icon: IconNorthStar,
    title: "bestexp",
    description:
      "bestexpdesc",
  },
];
