import { createRoot } from "react-dom/client";
import { App } from "./components/App";
import './i18n';
import { SelectedPromptProvider } from "./components/SelectedPromptContext"; 
import reportWebVitals from "./vitals/reportWebVitals";
import { sendToVercelAnalytics } from "./vitals/vitals";

const container = document.getElementById("app");
const root = createRoot(container!);

root.render(
    <SelectedPromptProvider>
        <App />
    </SelectedPromptProvider>
);

reportWebVitals(sendToVercelAnalytics);
