export const defaultModel = "gpt-3.5-turbo";

export const availableModels = [
  {
    value: "gpt-3.5-turbo",
    label: "GPT-3.5-TURBO (Default ChatGPT)",
  },
  { value: "gpt-3.5-turbo-0301", label: "GPT-3.5-TURBO-0301" },
  { value: "gpt-4", label: "GPT-4 (Limited Beta)" },
  { value: "gpt-4-0314", label: "GPT-4-0314 (Limited Beta)" },
  { value: "gpt-4-32k", label: "GPT-4-32K (Limited Beta)" },
  {
    value: "gpt-4-32k-0314",
    label: "GPT-4-32K-0314 (Limited Beta)",
  },
];

export const premadePrompts = [
  
  {
    label: "proofreader",
    value: "152"
  ,emoji: "✅",
  },
  {
    label: "ideation",
    value: "162",
    emoji: "🧐"
  },
    {
      label: "linuxterminal",
      value: "one"
      ,emoji: "🖥️"
    },
    {
      label: "englishtranslator",
      value: "2"
      ,emoji: "📚"
    },
    {
      label: "interviewer",
      value: "3",
      emoji: "💼"
    },
    {
      label: "javascriptconsole",
      value: "4"
      ,emoji: "⌨️"
    },
    {
      label: "excelsheet",
      value: "5"
      ,emoji: "📊"
    },
    {
      label: "spokenenglishteacher",
      value: "7"
      ,emoji: "🎤"
    },
    {
      label: "travelguide1",
      value: "8"
      ,emoji: "🌍"
    },
    {
      label: "plagiarismchecker",
      value: "9"
      ,emoji: "🕵️‍♂️"
    },
    {
      label: "character",
      value: "10"
      ,emoji: "🎭"
    },
    {
      label: "advertiser",
      value: "11"
      ,emoji: "📢"
    },
    {
      label: "storyteller",
      value: "12"
      ,emoji: "📖"
    },
    {
      label: "footballcommentator",
      value: "13"
      ,emoji: "⚽️"
    },
    {
      label: "standupcomedian1",
      value: "14"
      ,emoji: "🎙️"
    },
    {
      label: "motivationalcoach",
      value: "15"
      ,emoji: "🌟"
    },
    {
      label: "composer",
      value: "16"
      ,emoji: "🎼"
    },
    {
      label: "debater",
      value: "17"
      ,emoji: "📢"
    },
    {
      label: "debatecoach",
      value: "18"
      ,emoji: "🙆‍♂️"
    },
    {
      label: "screenwriter",
      value: "19"
      ,emoji: "🎞️"
    },
    {
      label: "novelist",
      value: "20"
      ,emoji: "📕"
    },
    {
      label: "moviecritic",
      value: "21"
      ,emoji: "🎥"
    },
    {
      label: "relationshipcoach",
      value: "22"
      ,emoji: "❤️"
    },
    {
      label: "poet",
      value: "23"
      ,emoji: "🖋️"
    },
    {
      label: "rapper",
      value: "24"
      ,emoji: "🎤"
    },
    {
      label: "motivationalspeaker",
      value: "25"
      ,emoji: "💡"
    },
    {
      label: "philosophyteacher",
      value: "26"
      ,emoji: "🧠"
    },
    {
      label: "philosopher",
      value: "27"
      ,emoji: "💭"
    },
    {
      label: "mathteacher",
      value: "28"
      ,emoji: "➕"
    },
    {
      label: "aiwritingtutor",
      value: "29"
      ,emoji: "🤖"
    },
    {
      label: "uxuideveloper",
      value: "30"
      ,emoji: "🎨"
    },
    {
      label: "cybersecurity",
      value: "31"
      ,emoji: "🔒"
    },
    {
      label: "recruiter",
      value: "32"
      ,emoji: "🤝"
    },
    {
      label: "lifecoach1",
      value: "33"
      ,emoji: " 🌈,"
    },
    {
      label: "etymologist",
      value: "34"
      ,emoji: "📜"
    },
    {
      label: "commentariat",
      value: "35"
      ,emoji: "🎙️"
    },
    {
      label: "magician",
      value: "36"
      ,emoji: "🎩"
    },
    {
      label: "careercounselor1",
      value: "37"
      ,emoji: "💼"
    },
    {
      label: "petbehaviorist",
      value: "38"
      ,emoji: "🐾"
    },
    {
      label: "personaltrainer1",
      value: "39"
      ,emoji: "🏋️"
    },
    {
      label: "mentalhealthadviser",
      value: "40"
      ,emoji: "🧘"
    },
    {
      label: "realestateagent",
      value: "41"
      ,emoji: "🏠"
    },
    {
      label: "logistician",
      value: "42"
      ,emoji: "🚚"
    },
    {
      label: "dentist",
      value: "43"
      ,emoji: "😁"
    },
    {
      label: "webdesign",
      value: "44"
      ,emoji: "🌐"
    },
    {
      label: "aiassisteddoctor",
      value: "45"
      ,emoji: "🩺"
    },
    {
      label: "doctor",
      value: "46"
      ,emoji: "👩‍⚕️"
    },
    {
      label: "accountant",
      value: "47"
      ,emoji: "💰"
    },
    {
      label: "chef",
      value: "48"
      ,emoji: "👩‍🍳"
    },
    {
      label: "automechanic",
      value: "49"
      ,emoji: "🚗"
    },
    {
      label: "artistadvisor",
      value: "50"
      ,emoji: "🎨"
    },
    {
      label: "financialanalyst",
      value: "51"
      ,emoji: "💹"
    },
    {
      label: "investmentmanager",
      value: "52"
      ,emoji: "📈"
    },
    {
      label: "teataster",
      value: "53"
      ,emoji: "🍵"
    },
    {
      label: "interiordecorator",
      value: "54"
      ,emoji: "🛋️"
    },
    {
      label: "florist",
      value: "55"
      ,emoji: "💐"
    },
    {
      label: "selfhelpbook",
      value: "56"
    ,emoji: "📘"
    },
    {
      label: "gnomist",
      value: "57"
    ,emoji: "🧙"
    },
    {
      label: "aphorismbook",
      value: "58"
    ,emoji: "📙"
    },
    {
      label: "textadventure",
      value: "59"
    ,emoji: "🎮"
    },
    {
      label: "aiinabox",
      value: "60"
    ,emoji: "📦"
    },
    {
      label: "fancytitlegenerator",
      value: "61"
    ,emoji: "✍️"
    },
    {
      label: "statistician",
      value: "62"
    ,emoji: "🔢"
    },
    {
      label: "promptgenerator",
      value: "63"
    ,emoji: "💡"
    },
    {
      label: "instructor",
      value: "64"
      ,emoji: "👩‍🏫"
    },
    {
      label: "sqlterminal",
      value: "65"
    ,emoji: "🧩"
    },
    {
      label: "dietitian",
      value: "66"
    ,emoji: "🍏"
    },
    {
      label: "psychologist",
      value: "67"
    ,emoji: "🛋️"
    },
    {
      label: "domainnamegen",
      value: "68"
    ,emoji: "🌐"
    },
    {
      label: "techreviewer",
      value: "69"
    ,emoji: "📱"
    },
    {
      label: "devrelations",
      value: "70"
    ,emoji: "👩‍💻"
    },
    {
      label: "academician",
      value: "71"
    ,emoji: "🎓"
    },
    {
      label: "itarchitect",
      value: "72"
    ,emoji: "🏗️"
    },
    {
      label: "lunatic",
      value: "73"
    ,emoji: "🤪"
    },
    {
      label: "gaslighter",
      value: "74"
    ,emoji: "🔥"
    },
    {
      label: "fallacyfinder",
      value: "75"
    ,emoji: "❌"
    },
    {
      label: "journalreviewer",
      value: "76"
    ,emoji: "📰"
    },
    {
      label: "diyexpert1",
      value: "77"
    ,emoji: "🔨"
    },
    {
      label: "influencer",
      value: "78"
    ,emoji: "🤳"
    },
    {
      label: "socrat",
      value: "79"
    ,emoji: "🏛️"
    },
    {
      label: "socraticmethod",
      value: "80"
    ,emoji: "❓"
    },
    {
      label: "educontentcreator",
      value: "81"
    ,emoji: "🍎"
    },
    {
      label: "yogi",
      value: "82"
    ,emoji: "🧘‍♂️"
    },
    {
      label: "essaywriter",
      value: "83",
      special: true
    ,emoji: "✍️"
    },
    {
      label: "socialmediamanager",
      value: "84"
    ,emoji: "📲"
    },
    {
      label: "elocutionist",
      value: "85"
    ,emoji: "📣"
    },
    {
      label: "datavisualizer",
      value: "86"
    ,emoji: "📊"
    },
    {
      label: "carnavigation",
      value: "87"
    ,emoji: "🚗"
    },
    {
      label: "hypnotherapist",
      value: "88"
    ,emoji: "🌀"
    },
    {
      label: "historian",
      value: "89"
    ,emoji: "📜"
    },
    {
      label: "astrologer",
      value: "90"
    ,emoji: "🔮"
    },
    {
      label: "filmcritic",
      value: "91"
    ,emoji: "🎬"
    },
    {
      label: "classicalcomposer",
      value: "92"
    ,emoji: "🎶"
    },
    {
      label: "journalist1",
      value: "93"
    ,emoji: "📝"
    },
    {
      label: "digitalartguide",
      value: "94"
    ,emoji: "🖼️"
    },
    {
      label: "publicspeakingcoach",
      value: "95"
    ,emoji: "🗣️"
    },
    {
      label: "makeupartist",
      value: "96"
    ,emoji: "💄"
    },
    {
      label: "babysitter",
      value: "97"
    ,emoji: "👶"
    },
    {
      label: "techwriter1",
      value: "98"
    ,emoji: "💻"
    },
    {
      label: "asciiartist",
      value: "99"
    ,emoji: "░"
    },
    {
      label: "pythoninterpreter",
      value: "100"
    ,emoji: "🐍"
    },
    {
      label: "synonymfinder",
      value: "101"
    ,emoji: "🔎"
    },
    {
      label: "personalshopper",
      value: "102"
    ,emoji: "🛍️"
    },
    {
      label: "foodcritic",
      value: "103"
    ,emoji: "🍽️"
    },
    {
      label: "virtualdoctor",
      value: "104"
    ,emoji: "💊"
    },
    {
      label: "personalchef",
      value: "105"
    ,emoji: "🍽️"
    },
    {
      label: "legaladvisor",
      value: "106"
    ,emoji: "⚖️"
    },
    {
      label: "personalstylist",
      value: "107"
    ,emoji: "👗"
    },
    {
      label: "mlengineer",
      value: "108"
    ,emoji: "🤖"
    },
    {
      label: "biblicaltranslator",
      value: "109"
    ,emoji: "📖"
    },
    {
      label: "svgdesigner",
      value: "110"
    ,emoji: "🌟"
    },
    {
      label: "itexpert",
      value: "111"
    ,emoji: "👨‍💻"
    },
    {
      label: "chessplayer",
      value: "112"
    ,emoji: "♟️"
    },
    {
      label: "midjourneyprompt",
      value: "113"
    ,emoji: "✏️"
    },
    {
      label: "fullstackdev",
      value: "114"
    ,emoji: "🖥️"
    },
    {
      label: "mathematician",
      value: "115"
    ,emoji: "√"
    },
    {
      label: "regexgenerator",
      value: "116"
    ,emoji: "📝"
    },
    {
      label: "timetravelguide",
      value: "117"
    ,emoji: "🕰️"
    },
    {
      label: "dreaminterpreter",
      value: "118"
    ,emoji: "💤"
    },
    {
      label: "talentcoach",
      value: "119"
    ,emoji: "🌟"
    },
    {
      label: "rinterpreter",
      value: "120"
    ,emoji: "📊"
    },
    {
      label: "stackoverflowpost",
      value: "121"
    ,emoji: "💬"
    },
    {
      label: "emojitranslator",
      value: "122"
    ,emoji: "😺"
    },
    {
      label: "phpinterpreter",
      value: "123"
    ,emoji: "📟"
    },
    {
      label: "emergencyresponse",
      value: "124"
    ,emoji: "🚨"
    },
    {
      label: "worksheetgenerator",
      value: "125"
    ,emoji: "📄"
    },
    {
      label: "softwaretester",
      value: "126"
    ,emoji: "🐜"
    },
    {
      label: "tictactoe",
      value: "127"
    ,emoji: "❌⭕"
    },
    {
      label: "passwordgenerator",
      value: "128"
    ,emoji: "🔐",
    special: true
    },
    {
      label: "roundtable",
      value: "161",
      emoji: "💡",
      special: true
    },
    {
      label: "languagecreator",
      value: "129"
    ,emoji: "🗨️"
    },
    {
      label: "webbrowser",
      value: "130"
    ,emoji: "🌐"
    },
    {
      label: "seniorfrontenddev",
      value: "131"
    ,emoji: "💻"
    },
    {
      label: "solrsearch",
      value: "132"
    ,emoji: "🔍"
    },
    {
      label: "startupideagen",
      value: "133"
    ,emoji: "💡"
    },
    {
      label: "pronunciationhelper",
      value: "6"
      ,emoji: "🗣️"
    },
    {
      label: "magicconch",
      value: "134"
    ,emoji: "🐚"
    },
    {
      label: "languagedetector",
      value: "135"
    ,emoji: "🌐"
    },
    {
      label: "salesperson",
      value: "136"
    ,emoji: "💼"
    },
    {
      label: "commitmessagegen",
      value: "137"
    ,emoji: "📝"
    },
    {
      label: "ceo",
      value: "138"
    ,emoji: "👔"
    },
    {
      label: "diagramgenerator",
      value: "139"
    ,emoji: "📈"
    },
    {
      label: "lifecoach",
      value: "140"
    ,emoji: "✅"
    },
    {
      label: "slppathologist",
      value: "141"
    ,emoji: "👄"
    },
    {
      label: "techlawyer",
      value: "142"
    ,emoji: "⚖️"
    },
    {
      label: "titlegenerator",
      value: "143"
    ,emoji: "✍️"
    },
    {
      label: "productmanager1",
      value: "144"
    ,emoji: "📋"
    },
    {
      label: "drunkperson",
      value: "145"
    ,emoji: "🍻"
    },
    {
      label: "mathhistoryteacher",
      value: "146"
    ,emoji: "🧮"
    },
    {
      label: "songrecommender",
      value: "147"
    ,emoji: "🎵"
    },
    {
      label: "coverletter",
      value: "148"
    ,emoji: "📄"
    },
    {
      label: "techtransferer",
      value: "149"
    ,emoji: "💼"
    },
    {
      label: "unconstrainedai",
      value: "150"
    ,emoji: "🌀"
    },
    {
      label: "gomokuplayer",
      value: "151"
    ,emoji: "⚫⚪"
    },
    {
      label: "buddha",
      value: "153"
    ,emoji: "☸️"
    },
    {
      label: "muslimimam",
      value: "154"
    ,emoji: "🕌"
    },
    {
      label: "chemicalreactor",
      value: "155"
    ,emoji: "⚗️"
    },
    {
      label: "friend",
      value: "156"
    ,emoji: "👫",
    special: true
    },
    {
      label: "pythoninterpreter1",
      value: "157"
    ,emoji: "🐍"
    },
    {
      label: "chatgptprompt",
      value: "158"
    ,emoji: "🗨️"
    },
    {
      label: "wikipediapage",
      value: "159"
    ,emoji: "🌐"
    },
    {
      label: "kanjiquiz",
      value: "160"
    ,emoji: "🈸"
    }
]

export const writingCharacters = [
  {
    label: "standupcomedian",
    value: "You are a standup comedian, you make people laugh with your satire, jokes and humor. You answer everything in a humorous way to cheer the user up. Use satire and make fun of everything the user says in a positive way.",
    description: "descstandupcomedian",
    color: "#FFD180",
  },
  {
    label: "lifecoach",
    value: "You are a life coach, you help the user identify and achieve their goals, motivate them, and provide support and encouragement.",
    description: "desclifecoach",
    color: "#FFD9D9",
  },
  {
    label: "careercounselor",
    value:
      "You are a career counselor, you help the user with their career-related concerns, such as job searching, resume writing, interview preparation, networking, and career planning.",
    description: "desccareercounselor",
    color: "#FFC3A0",
  },
  {
    label: "nutritionist",
    value: 
      "You are a nutritionist, you help the user with their nutritional needs by providing advice on healthy eating habits, recipes, dietary restrictions and recommendations.",
    description: "descnutritionist",
    color: "#FFFFB4",
  },
  {
    label: "productmanager",
    value:
      "You are a product manager, you oversee the development and marketing of a product, identify customer needs, and work with engineers and designers to create a product roadmap.",
    description: "descproductmanager",
    color: "#E2F0CB",
  },
  {
    label: "personaltrainer",
    value:
      "You are a personal trainer, you help the user create and follow a personalized fitness plan, track progress, and provide guidance and motivation through workout routines.",
    description: "descpersonaltrainer",
    color: "#B5EAD7",
  },
  {
    label: "lifehacker",
    value:
      "You are a life hacker, you provide useful tips and tricks to help the user optimize their daily routines, save time, and increase productivity.",
    description: "desclifehacker",
    color: "#B8E2F6",
  },
  {
    label: "traveladvisor",
    value:
      "You are a travel advisor, you suggest travel destinations based on the user's preferences and budget, provide recommendations on transportation, accommodations, and activities, and help plan the itinerary.",
    description: "desctraveladvisor",
    color: "#CBB4D4",
  },
  {
    label: "mindfulnesscoach",
    value:
      "You are a mindfulness coach, you guide the user through meditation and relaxation techniques, help them focus on the present moment, and reduce stress and anxiety.",
    description: "descmindfulnesscoach",
    color: "#D5C7BC",
  },
  {
    label: "financialadvisor",
    value:
      "You are a financial advisor, you provide advice on budgeting, saving, investing, retirement planning, and other financial issues.",
    description: "descfinancialadvisor",

    color: "#FFB6B9",
  },
  {
    label: "languagetutor",
    value:
      "You are a language tutor, you help the user improve their language skills through conversation practice, vocabulary building, and grammar lessons.",
    description: "desclanguagetutor",

    color: "#FFA07A",
  },
  {
    label: "travelguide",
    value:
      "You are a travel guide, you provide information about tourist attractions, historical sites, culture, and customs of various travel destinations.",
    description: "desctravelguide",

    color: "#FF8C00",
  },
  {
    label: "marketingexpert",
    value:
      "You are a marketing expert, you help the user develop marketing strategies and campaigns, conduct market research, and provide branding and advertising advice.",
    description:"descmarketingexpert",

    color: "#FFDAB9",
  },
  {
    label: "softwaredeveloper",
    value:
      "You are a software developer, you develop software programs and applications using programming languages and development tools.",
    description: "descsoftwaredeveloper",
    color: "#F0E68C",
  },
  {
    label: "datingcoach",
    value:
      "You are a dating coach, you help the user with their dating and relationship concerns, such as online dating, first dates, communication, and breakup advice.",
    description: "descdatingcoach",

    color: "#90EE90",
  },
  {
    label: "diyexpert",
    value:
      "You are a DIY expert, you provide advice and guidance on do-it-yourself projects, such as home improvement, woodworking, and crafting.",
    description: "descdiyexpert",
    color: "#ADD8E6",
  },
  {
    label: "journalist",
    value:
      "You are a journalist, you research and report on newsworthy events, conduct interviews, and write news articles and stories.",
    description: "descjournalist",
    color: "#AFEEEE",
  },
  {
    label: "techwriter",
    value:
      "You are a tech writer, you write technical documentation, manuals, and guides for software, hardware, and technology products.",
    description: "desctechwriter",
    color: "#B0C4DE",
  },
  {
    label: "professionalchef",
    value:
      "You are a professional chef, you cook and prepare meals in a variety of cuisines, and provide advice on cooking techniques and ingredients.",
    description: "descprofessionalchef",
    color: "#BA55D3",
  },
  {
    label: "professionalsalesperson",
    value:
      "You are a professional salesperson, you sell products or services, negotiate deals, and provide advice on sales strategies and techniques.",
    description: "descprofessionalsalesperson",
    color: "#D8BFD8",
  },
  {
    label: "startuptechlawyer",
    value:
      "You are a startup tech lawyer, you provide legal advice and guidance to startups and technology businesses on intellectual property, contracts, and regulatory compliance.",
    description: "descstartuptechlawyer",
    color: "#F08080",
  },
  {
    label: "graphicdesigner",
    value:
      "You are a graphic designer, you design graphics and visual materials for various media such as websites, advertisements, and branding. You make use of typography, imagery and creative layout to communicate ideas and messages visually. Always strive to create unique designs that will stand out and grab attention.",
    description: "descgraphicdesigner",
    color: "#FA8072",
  },
  {
    label: "academicresearcher",
    value:
      "You are an academic researcher, you conduct research, analyze data, and publish findings in academic journals and conferences.",
    description: "descacademicresearcher",
    color: "#FFDAB9",
  },
  {
    label: "customersupportagent",
    value:
      "You are a customer support agent, you provide help and support to customers with their inquiries, complaints, and other related issues.",
    description: "desccustomersupportagent",
    color: "#FFA07A",
  },
  {
    label: "hrconsultant",
    value:
      "You are an HR consultant, you provide advice and guidance to businesses and organizations on human resources issues, such as recruiting, hiring, training, and employee relations.",
    description: "deschrconsultant",
    color: "#FFB6C1",
  },
];

export const writingStyles = [
  "academic",
  "analytical",
  "argumentative",
  "conversational",
  "creative",
  "critical",
  "descriptive",
  "explanatory",
  "informative",
  "instructive",
  "investigative",
  "journalistic",
  "metaphorical",
  "narrative",
  "persuasive",
  "poetic",
  "satirical",
  "technical"
];

export const writingFormats = [
  { value: "Answer as concise as possible", label: "concise" },
  { value: "Think step-by-step", label: "step" },
  { value: "Answer in painstakingly detail", label: "detail" },
  { value: "Explain like I'm five", label: "five" },
];
