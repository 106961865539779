import {
  Alert,
  Anchor,
  Button,
  Flex,
  List,
  Modal,
  PasswordInput,
  Select,
  Stack,
  Text,
  Group,
  Paper,
  Col,
  Grid,
  Card,
  Avatar,
  Tooltip,
} from "@mantine/core";
import {
  IconGift,
  IconCircleCheckFilled,
  IconCheck,
  IconStar,
} from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { SettingsModal } from "./SettingsModal";


interface CountdownProps {
  endDate: Date;
}

export function FeaturesModal() {
  const { t, i18n } = useTranslation();
  const [opened, { open, close }] = useDisclosure();
  const endDate = new Date("2023-04-19");

  function Countdown({ endDate }: CountdownProps) {
    const [timeRemaining, setTimeRemaining] = useState(
      endDate.getTime() - new Date().getTime()
    );

    useEffect(() => {
      const timer = setInterval(() => {
        setTimeRemaining(endDate.getTime() - new Date().getTime());
      }, 1000);

      return () => clearInterval(timer);
    }, [endDate]);
    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    return (
      <Text ta="center" fz="xl" c="blue">{days}д {hours}ч {minutes}м {seconds}с</Text>
    );
  }

  return (
    <>
      <Modal opened={opened} onClose={close} withCloseButton={false}>
        <Text variant="gradient"
      gradient={{ from: 'indigo', to: 'cyan', deg: 45 }} ta="center" style={{ color: "#004953", marginBottom: -25 }}>
          <h2>{t('onetime')}</h2>
        </Text>
        <Stack spacing="lg" p="lg">
          <Group position="center" spacing="sm" style={{ marginBottom: -27}} >
            <Text td="line-through" c="dimmed" fz="xl">
              <h3>8890₸</h3>
            </Text>
            <Text c="green" fz="2xl">
              <h1>2890₸</h1>
            </Text>
          </Group>
          {/* <Group position="center" style={{ marginBottom: -5}} >
            <Text ta="center" c="gray" ml="sm">
            {t('dealends')}
            </Text>
            <Countdown endDate={endDate} />
          </Group> s*/}
          <Text fz="xs" c="dimmed" ta="center" style={{ marginBottom: -20}}>
            {t('buyonce')}
          </Text>
          <Text fz="xs" c="green" ta="center">
            {t('discountprice')}
          </Text>
          <Stack align="center" spacing="sm" mb="-lg">
            <Group  spacing="xs">
              <Avatar  size="sm" color="green" radius="xl"><IconCircleCheckFilled></IconCircleCheckFilled></Avatar>
              <Text fz="sm">{t('secured')}</Text>
            </Group>
            <Group  spacing="xs">
              <Avatar size="sm" color="green" radius="xl"><IconCircleCheckFilled></IconCircleCheckFilled></Avatar>
              <Text fz="sm">{t('library')}</Text>
            </Group>
            <Group  spacing="xs">
              <Avatar size="sm" color="green" radius="xl"><IconCircleCheckFilled></IconCircleCheckFilled></Avatar>
              <Text fz="sm">{t('faster')}</Text>
            </Group>
            <Group  spacing="xs">
              <Avatar size="sm" color="green" radius="xl"><IconCircleCheckFilled></IconCircleCheckFilled></Avatar>
              <Text fz="sm">{t('searchmsg')}</Text>
            </Group>
      </Stack>
      <Grid mt="lg" gutter="lg" grow>
        <Col span={6}>
          <Tooltip label={t('clicktopurchase')} position="top">
            <Button
              component="a"
              variant="gradient"
              gradient={{ from: "teal", to: "blue" }}
              href="https://chatai.lemonsqueezy.com/checkout/buy/5e11513e-6280-4a67-9eea-77cb9e55573c"
              fullWidth
              style={{
                transition: "transform 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "scale(1.05)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "scale(1)";
              }}
            >
              {t('purchase')}
            </Button>
          </Tooltip>
        </Col>
      </Grid>
      <SettingsModal>
        <Text 
        component="a" 
        role="button"
        ta="center"
        td="underline"
        c="blue"
        fw={700}
        fz="sm"
        style={{ cursor: "pointer"}} 
        onClick={() => {}} >
          {t("havelicense")}
        </Text>
      </SettingsModal>

      <Text fz="xs" c="dimmed" ta="center">{t('note')}</Text>
    </Stack>
  </Modal>
    <Button
      variant="outline"
      onClick={open}
      size="md"
      leftIcon={<IconGift size={20} />}
      style={{
        borderColor: "#ff6347",
        color: "#ff6347",
        transition: "transform 0.3s",
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "scale(1.05)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "scale(1)";
      }}
    >
      {t('buylicense')}
    </Button>
</>
);
}