import type { Metric } from 'web-vitals';

type PerfEntryHandler = (metric: Metric) => void;

const reportWebVitals = (onPerfEntry?: PerfEntryHandler): void => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(
      ({ onCLS, onFID, onFCP, onLCP, onTTFB }) => {
        onCLS(onPerfEntry);
        onFID(onPerfEntry);
        onFCP(onPerfEntry);
        onLCP(onPerfEntry);
        onTTFB(onPerfEntry);
      },
    );
  }
};

export default reportWebVitals;
