import { Button, Modal, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconTrash } from "@tabler/icons-react";
import { db } from "../db";
import { useTranslation } from "react-i18next";

export function DeleteAllDataModal({ onOpen }: { onOpen: () => void }) {
  const [opened, { open, close }] = useDisclosure(false, { onOpen });
  const { t } = useTranslation();

  return (
    <>
      <Button
        onClick={open}
        variant="outline"
        color="red"
        leftIcon={<IconTrash size={20} />}
      >
        {t('deleteall')}
      </Button>
      <Modal
        opened={opened}
        onClose={close}
        title={t('deleteall')}
        size="md"
        withinPortal
      >
        <Stack>
          <Text size="sm">{t('confirmdelall')}</Text>
          <Button
            onClick={async () => {
              await db.delete();
              localStorage.clear();
              window.location.assign("/");
            }}
            color="red"
          >
            {t('delete')}
          </Button>
        </Stack>
      </Modal>
    </>
  );
}
