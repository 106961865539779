import {
  ActionIcon,
  Box,
  Card,
  Code,
  CopyButton,
  Flex,
  Table,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import React from "react";
import { Prism } from "@mantine/prism";
import { IconCopy, IconUser } from "@tabler/icons-react";
import { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Message } from "../db";
import "../styles/markdown.scss";
import { CreatePromptModal } from "./CreatePromptModal";
import { LogoIcon } from "./Logo";
import { useTranslation } from "react-i18next";
import { ScrollIntoView } from "./ScrollIntoView";

export function MessageItem({ message }: { message: Message }) {
  const clipboard = useClipboard({ timeout: 500 });
  const { t } = useTranslation();
  const wordCount = useMemo(() => {
    var matches = message.content.match(/[\w\d\’\'-\(\)]+/gi);
    return matches ? matches.length : 0;
  }, [message.content]);

  return (
    <ScrollIntoView>
      <Card withBorder>
        <Flex gap="sm">
          {message.role === "user" && (
            <ThemeIcon color="gray" size="xl">
              <IconUser size={23} />
            </ThemeIcon>
          )}
          {message.role === "assistant" && <LogoIcon style={{ height: 42, width: 42}} />}
          <Box sx={{ flex: 1, width: 0 }} className="markdown">
            <ReactMarkdown
              children={message.content.toString()}
              remarkPlugins={[remarkGfm]}
              components={{
                table: ({ node, ...props }) => (
                  <Table verticalSpacing="sm" highlightOnHover {...props} />
                ),
                code: ({ node, className, children, ...props }) => {
                  const code = React.Children.toArray(children).join("");
                  const language = className ? className.replace(/language-/, "") : "";
                  return (
                    <Prism language={language as any}>
                      {code}
                    </Prism>
                  );
                },
              }}
            />
            {message.role === "assistant"}
          </Box>
          <Box>
            <CreatePromptModal content={message.content} />
            <CopyButton value={message.content}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? t("copied") : t("copy")} position="left">
                  <ActionIcon onClick={copy}>
                    <IconCopy opacity={0.5} size={20} />
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
            {/* <Tooltip label={`${wordCount} words`} position="left">
              <ActionIcon>
                <IconInfoCircle opacity={0.5} size={20} />
              </ActionIcon>
            </Tooltip> */}
          </Box>
        </Flex>
      </Card>
    </ScrollIntoView>
  );
}
