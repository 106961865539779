  import {
    Alert,
    Anchor,
    Button,
    Flex,
    List,
    Modal,
    PasswordInput,
    Select,
    Stack,
    Text,
  } from "@mantine/core";
  import { useDisclosure, useViewportSize } from "@mantine/hooks";
  import { notifications } from "@mantine/notifications";
  import { useLiveQuery } from "dexie-react-hooks";
  import { cloneElement, ReactElement, useEffect, useState } from "react";
  import { db } from "../db";
  import { availableModels, defaultModel } from "../utils/constants";
  import { checkOpenAIKey } from "../utils/openai";
  import { useTranslation } from "react-i18next";
  import { IconAlertCircle } from '@tabler/icons-react';
  
  import Confetti from "react-confetti";

  import { LicenseKey } from "../hooks/typesLS";
  import { getLicenseKey, activateLicenseKey } from "./License";

  export function SettingsModal({ children }: { children: ReactElement }) {
    const [opened, { open, close }] = useDisclosure(false);
    const [submitting, setSubmitting] = useState(false);
    const { t } = useTranslation();

    const [value, setValue] = useState("");
    const [model, setModel] = useState(defaultModel);

    const [licenseKey, setLicenseKey] = useState("");
    const [licenseValid, setLicenseValid] = useState<boolean | null>(null);

    const [showConfetti, setShowConfetti] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const { height, width} = useViewportSize();

    const settings = useLiveQuery(async () => {
      return db.settings.where({ id: "general" }).first();
    });

    const handleValidateLicenseKey = async (licenseKey: string) => {
      try {
        const result: LicenseKey = await getLicenseKey({ licenseKey });
        if (result.valid) {
          // Add activation call here
          const activateResult = await activateLicenseKey({
            licenseKey,
            instanceName: "LSapp", // Replace with your desired instance name
          });

          if (activateResult.activated === true) {
                  setLicenseValid(true);
                  setShowConfetti(true);
                  setTimeout(() => {
                    setIsComplete(true);
                  }, 2000);
                  notifications.show({
                    title: t("success"),
                    message: t("lkvalid"),
                  });

                  // Save the license key in the database
                  await db.settings.where({ id: "general" }).modify((setting) => {
                    setting.dblicenseKey = licenseKey;
                  });
                } else {
                  setLicenseValid(false);
                  setShowConfetti(false);
                  notifications.show({
                    title: t("error"),
                    color: "red",
                    message: t("lkinvalid"),
                  });
                }
              } else {
                setLicenseValid(false);
                setShowConfetti(false);
                notifications.show({
                  title: t("error"),
                  color: "red",
                  message: t("lkinvalid"),
                });
              }
            } catch (error) {
              setLicenseValid(false);
              setShowConfetti(false);
              notifications.show({
                title: t("error"),
                color: "red",
                message: t("lkerror"),
              });
            }
          };

    useEffect(() => {
      if (settings?.openAiApiKey) {
        setValue(settings.openAiApiKey);
      }
      if (settings?.openAiModel) {
        setModel(settings.openAiModel);
      }
      if (settings?.dblicenseKey) {
        setLicenseKey(settings.dblicenseKey)
      }
    }, [settings]);

    return (
      <>
        {cloneElement(children, { onClick: open })}
        <Modal opened={opened} onClose={close} title={t('settings')} size="lg">
          <Stack>
          <form>
              <Flex gap="xs" align="end">
                <PasswordInput
                  label={t('lk')}
                  placeholder={t('enterlk') ?? ""}
                  sx={{ flex: 1 }}
                  value={licenseKey}
                  onChange={(event) => setLicenseKey(event.currentTarget.value)}
                  formNoValidate
                />
                <Button
                  type="button"
                  onClick={() => handleValidateLicenseKey(licenseKey)}
                >
                  {t('activate')}
                </Button>
              </Flex>
              {/* Display the license key validation status */}
              {licenseValid === true && (
                <Alert icon={<IconAlertCircle size="1rem" />} color="green" title={t('success')}>
                  {t('lkactivated')}
                </Alert>
              )}
              {licenseValid === false && (
                <Alert icon={<IconAlertCircle size="1rem" />} color="red" title={t('invalid')}>
                  {t('lkinvalid')}
                </Alert>
              )}
            </form>
            <form
              onSubmit={async (event) => {
                try {
                  setSubmitting(true);
                  event.preventDefault();
                  await checkOpenAIKey(value);
                  await db.settings.where({ id: "general" }).modify((apiKey) => {
                    apiKey.openAiApiKey = value;
                    console.log(apiKey);
                  });
                  notifications.show({
                    title: t('saved'),
                    message: t('keysaved'),
                  });
                } catch (error: any) {
                  if (error.toJSON().message === "Network Error") {
                    notifications.show({
                      title: "Error",
                      color: "red",
                      message: "No internet connection.",
                    });
                  }
                  const message = error.response?.data?.error?.message;
                  if (message) {
                    notifications.show({
                      title: "Error",
                      color: "red",
                      message,
                    });
                  }
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              <Flex gap="xs" align="end">
                <PasswordInput
                  label={t('aiapikey')}
                  placeholder="sk-xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                  sx={{ flex: 1 }}
                  value={value}
                  onChange={(event) => setValue(event.currentTarget.value)}
                  formNoValidate
                />
                <Button type="submit" loading={submitting}>
                  {t('save')}
                </Button>
              </Flex>
            </form>
            <List withPadding>
              <List.Item>
                <Text size="sm">
                  <Anchor
                    href="https://platform.openai.com/account/api-keys"
                    target="_blank"
                  >
                    {t('getkey')}
                  </Anchor>
                </Text>
              </List.Item>
              <List.Item>
                <Text size="sm" color="dimmed">
                  {t('storedlocal')}
                </Text>
              </List.Item>
            </List>
            <Select
              label={t('aimodel')}
              value={model}
              onChange={(value) => {
                db.settings.update("general", {
                  openAiModel: value ?? undefined,
                });
              }}
              withinPortal
              data={availableModels}
            />
            <Alert icon={<IconAlertCircle size="1rem" />} color="orange" title={t('warning')}>
              {t('alertmsg')}
            </Alert>
          </Stack>
        </Modal>
        <Confetti run={showConfetti} width={width} height={height} recycle={!isComplete}/>
      </>
    );
  }
