import {
  Box,
  Button,
  Card,
  Container,
  Flex,
  MediaQuery,
  Select,
  SimpleGrid,
  Skeleton,
  Stack,
  Textarea,
  Loader,
  Modal,
  Group,
  Grid,
  Text
} from "@mantine/core";
import { IconSpeakerphone, IconSchool } from "@tabler/icons-react";
import { LogoIcon } from "../components/Logo";
import { notifications } from "@mantine/notifications";
import { useLiveQuery } from "dexie-react-hooks";
import { findLast } from "lodash";
import { nanoid } from "nanoid";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineSend } from "react-icons/ai";
import { MessageItem } from "../components/MessageItem";
import { db } from "../db";
import { useChatId } from "../hooks/useChatId";
import {
  writingFormats,
  writingStyles,
} from "../utils/constants";
import { createChatCompletion } from "../utils/openai";
import { CharacterModal } from "../components/CharactersModal";
import { PromptsModal } from "../components/PromptsModal";
import { FeaturesModal } from "../components/FeaturesModal";
import { SettingsModal } from "../components/SettingsModal";
import { useSelectedPrompt } from "../components/SelectedPromptContext";

export function ChatRoute() {
  const chatId = useChatId();
  const apiKey = useLiveQuery(async () => {
    return (await db.settings.where({ id: "general" }).first())?.openAiApiKey;
  });
  const licenseKey = useLiveQuery(async() => {
    return (await db.settings.where({ id: "general"}).first())?.dblicenseKey;
  });
  const messages = useLiveQuery(() => {
    if (!chatId) return [];
    return db.messages.where("chatId").equals(chatId).sortBy("createdAt");
  }, [chatId]);
  const [content, setContent] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const chat = useLiveQuery(async () => {
    if (!chatId) return null;
    return db.chats.get(chatId);
  }, [chatId]);

  const [writingCharacter, setWritingCharacter] = useState("");
  const [writingStyle, setWritingStyle] = useState<string | null>(null);
  const [writingFormat, setWritingFormat] = useState<string | null>(null);
  

  const { t, i18n } = useTranslation(); // to translate received messages from different languages
  function handleWritingCharacter(character: string) {
    setWritingCharacter(character);
  }
  const selectData = writingFormats.map((format) => ({
    label: t(format.label),
    value: format.value,
  }));
  const selectStyle = writingStyles.map((style) => t(style));


  const { selectedPromptContent, setSelectedPromptContent } = useSelectedPrompt();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (selectedPromptContent) {
      setContent(selectedPromptContent);
      setSelectedPromptContent(""); // Reset the selected prompt content to avoid setting it again when navigating to other chats
    }
  }, [selectedPromptContent, setSelectedPromptContent]);

  const getSystemMessage = () => {
    const message: string[] = [];
    if (writingCharacter) message.push(`${writingCharacter}. Respond only in${t('language')}`);
    if (writingStyle) message.push(`Respond in ${writingStyle} style. Respond in ${t('language')}`);
    if (writingFormat) message.push(writingFormat);
    if (message.length === 0)
      message.push(
        `You are ChatGPT, a large language model trained by OpenAI. You have to respond only in ${t('language')}`
      );
    return message.join(" ");
  };

  const submit = async () => {
    if (submitting) return;

    if (!chatId) {
      notifications.show({
        title: "Error",
        color: "red",
        message: "chatId is not defined. Please create a chat to get started.",
      });
      return;
    }

    if (!apiKey) {
      setShowModal(true);
      notifications.show({
        title: t('error'),
        color: "red",
        message: t('keynotdefined'),
      });
      return;
    }
    if (!licenseKey) {
      setShowModal(true);
      return;
    }

    try {
      setSubmitting(true);

      await db.messages.add({
        id: nanoid(),
        chatId,
        content,
        role: "user",
        createdAt: new Date(),
      });
      setContent("");

      const result = await createChatCompletion(apiKey, [
        {
          role: "system",
          content: getSystemMessage(),
        },
        ...(messages ?? []).map((message) => ({
          role: message.role,
          content: message.content,
        })),
        { role: "user", content },
      ]);

      const assistantMessage = result.data.choices[0].message?.content;
      if (result.data.usage) {
        await db.chats.where({ id: chatId }).modify((chat) => {
          if (chat.totalTokens) {
            chat.totalTokens += result.data.usage!.total_tokens;
          } else {
            chat.totalTokens = result.data.usage!.total_tokens;
          }
        });
      }
      setSubmitting(false);

      await db.messages.add({
        id: nanoid(),
        chatId,
        content: assistantMessage ?? "unknown reponse",
        role: "assistant",
        createdAt: new Date(),
      });

      if (chat?.description === t('newchat')) {
        const messages = await db.messages
          .where({ chatId })
          .sortBy("createdAt");
        const createChatDescription = await createChatCompletion(apiKey, [
          {
            role: "system",
            content: getSystemMessage(),
          },
          ...(messages ?? []).map((message) => ({
            role: message.role,
            content: message.content,
          })),
          {
            role: "user",
            content:
              `What would be a short and relevant title for this chat ? You must strictly answer with only the title, no other text is allowed. Respond only in ${t('language')}`,
          },
        ]);
        const chatDescription =
          createChatDescription.data.choices[0].message?.content;

        if (createChatDescription.data.usage) {
          await db.chats.where({ id: chatId }).modify((chat) => {
            chat.description = chatDescription ?? t('newchat');
            if (chat.totalTokens) {
              chat.totalTokens += createChatDescription.data.usage!.total_tokens;
            } else {
              chat.totalTokens = createChatDescription.data.usage!.total_tokens;
            }
          });
        }
      }
    } catch (error: any) {
      if (error.toJSON().message === "Network Error") {
        notifications.show({
          title: "Error",
          color: "red",
          message: "No internet connection.",
        });
      }
      const message = error.response?.data?.error?.message;
      if (message) {
        notifications.show({
          title: "Error",
          color: "red",
          message,
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  if (!chatId) return null;

  return (
    <>
      <Modal
        opened={showModal}
        onClose={() => setShowModal(false)}
        title={t("warning")}
        size="md"
        lockScroll={true}
      >
        
        {!apiKey && 
        <Stack>
          <Text>{t('setkey')}</Text>
          <SettingsModal>
            <Button size="sm" >
              <Text>
                {t('settings')}
              </Text>
            </Button>
          </SettingsModal>
        </Stack>}
      
        {!licenseKey && 
        <Stack>
          <Text>{t("setlicense")}</Text>
          <FeaturesModal />
        </Stack>
        }
          
      </Modal>
      <Container pt="xl" pb={100}>
        <Stack spacing="xs">
          {messages?.map((message) => (
            <MessageItem key={message.id} message={message} />
          ))}
        </Stack>
        {submitting && (
          <Card withBorder mt="xs">
            <Group spacing="sm">
              <LogoIcon style={{ height: 44, width: 44}} />
              <Text fz="lg" c="dimmed" fs="italic" fw={600} variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }}>
                {t('typing')}
              </Text> 
              <Loader size="sm"  /> 
              
            </Group>
          </Card>
        )}
      </Container>
      <Box
        py="lg"
        sx={(theme) => ({
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          [`@media (min-width: ${theme.breakpoints.md})`]: {
            left: 300,
          },
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[0],
        })}
      >
        <Container>
          {messages?.length === 0 && (
            <SimpleGrid
              mb="sm"
              spacing="xs"
              breakpoints={[
                { minWidth: "sm", cols: 4 },
                { maxWidth: "sm", cols: 2 },
              ]}
            >
              <CharacterModal onSelect={handleWritingCharacter}></CharacterModal>
              <PromptsModal onSelect={setContent}></PromptsModal>
              <Select
                value={writingStyle}
                onChange={setWritingStyle}
                data={selectStyle}
                placeholder={t("style") ?? ""}
                variant="filled"
                searchable
                clearable
                icon={<IconSpeakerphone size="1rem"/>}
                sx={{ flex: 1 }}
              />
              <Select
                value={writingFormat}
                onChange={setWritingFormat}
                data={selectData}
                placeholder={t("format") ?? ""}
                variant="filled"
                searchable
                clearable
                icon={<IconSchool size="1rem"/>}
                sx={{ flex: 1 }}
              />
            </SimpleGrid>
          )}
          <Flex gap="sm">
            <Textarea
              key={chatId}
              sx={{ flex: 1 }}
              placeholder={t('placehold') ?? ""}
              autosize
              autoFocus
              disabled={submitting}
              minRows={1}
              maxRows={5}
              value={content}
              onChange={(event) => setContent(event.currentTarget.value)}
              onKeyDown={async (event) => {
                if (event.code === "Enter" && !event.shiftKey) {
                  event.preventDefault();
                  submit();
                }
                if (event.code === "ArrowUp") {
                  const { selectionStart, selectionEnd } = event.currentTarget;
                  if (selectionStart !== selectionEnd) return;
                  if (selectionStart !== 0) return;
                  event.preventDefault();
                  const nextUserMessage = findLast(
                    messages,
                    (message) => message.role === "user"
                  );
                  setContent(nextUserMessage?.content ?? "");
                }
                if (event.code === "ArrowDown") {
                  const { selectionStart, selectionEnd } = event.currentTarget;
                  if (selectionStart !== selectionEnd) return;
                  if (selectionStart !== event.currentTarget.value.length)
                    return;
                  event.preventDefault();
                  const lastUserMessage = findLast(
                    messages,
                    (message) => message.role === "user"
                  );
                  if (lastUserMessage?.content === content) {
                    setContent("");
                  }
                }
              }}
            />
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Button
                h="auto"
                onClick={() => {
                  submit();
                }}
              >
                <AiOutlineSend />
              </Button>
            </MediaQuery>
          </Flex>
        </Container>
      </Box>
    </>
  );
}
