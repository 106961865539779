import { useState, useEffect } from "react";
import {
    Button,
    Modal,
    SimpleGrid,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { writingCharacters } from "../utils/constants";
import { useTranslation } from "react-i18next";
import "../styles/markdown.scss";
import { useMantineTheme } from '@mantine/core';


interface Character {
    label: string;
    value: string;
    color: string;
    description: string;
  }
  
interface CharacterModalProps {
    onSelect: (selectedCharacter: string) => void;
    }

export function CharacterModal({onSelect}: CharacterModalProps) {
    const { t, i18n } = useTranslation(); 
    const [opened, {open, close }] = useDisclosure(false);
    const [buttonText, setButtonText] = useState(t("selectcharacter"));
    const { colorScheme } = useMantineTheme();
    const isDarkMode = colorScheme === "dark";
    const isMobile = useMediaQuery("(max-width: 50em)");

    useEffect(() => {
        setButtonText(t("selectcharacter"));
    }, [i18n.language]);

    function handleSelect(character: Character) {
        onSelect(character.value);
        const buttonText = t(character.label);
        setButtonText(buttonText);
        close();
      }
    function handleCancel() {
        onSelect("");
        setButtonText(t("selectcharacter"));
        close();
    }
    return (
        <>
            <Button onClick={open}> {buttonText} </Button>
            <Modal opened={opened} onClose={handleCancel} size="lg" title={t("characters")} >
                <SimpleGrid cols={isMobile ? 1 : 2} spacing={isMobile ? "sm" : "lg"} style={{ padding: isMobile ? "20px" : "40px" }}>
                    {writingCharacters.map((character: Character, index: number) => (
                        <div 
                            key={index}
                            className={`character-card ${isDarkMode ? "dark-mode" : ""}`}
                            style={{ backgroundColor: character.color, padding: "5px",cursor: "pointer",
                            width: isMobile ? "100%" : "auto",
                            height: isMobile ? "auto" : "280px",
                            }}
                            onClick={() => handleSelect(character)}
                        >
                            <h3 style={{ fontSize: isMobile ? "1.2rem" : "1.5rem", marginBottom: "10px" }}>{t(character.label)}</h3>
                            <p style={{ fontSize: isMobile ? "1rem" : "1.2rem" }}>{t(character.description)}</p>
                        </div>
                    ))}
                </SimpleGrid>
            </Modal>
        </>
    )
}