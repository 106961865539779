import { ActivateLicenseResponse, LicenseKey } from '../hooks/typesLS';

const BASE_ENDPOINT = `https://api.lemonsqueezy.com`;

const LICENSE_KEYS_ENDPOINT = [BASE_ENDPOINT, `v1/licenses`].join(`/`);

const VALIDATE_LICENSE_KEY_ENDPOINT = [LICENSE_KEYS_ENDPOINT, `validate`].join('/');

const ACTIVATE_LICENSE_KEY_ENDPOINT = [LICENSE_KEYS_ENDPOINT, `activate`].join('/');

export async function getLicenseKey(params: {
  licenseKey: string;
}): Promise<LicenseKey> {
  const { licenseKey } = params;

  const response = await fetch(VALIDATE_LICENSE_KEY_ENDPOINT, {
    method: `POST`,
    headers: getHeaders(),
    body: new URLSearchParams({
      license_key: licenseKey,
    }).toString(),
  });

  if (!response.ok) {
    throw response;
  }

  return (await response.json()) as LicenseKey;
}

export async function activateLicenseKey(params: {
  licenseKey: string;
  instanceName: string;
}): Promise<ActivateLicenseResponse> {
  const { licenseKey, instanceName } = params;

  const response = await fetch(ACTIVATE_LICENSE_KEY_ENDPOINT, {
    method: `POST`,
    headers: getHeaders(),
    body: new URLSearchParams({
      license_key: licenseKey,
      instance_name: instanceName,
    }).toString(),
  });

  if (!response.ok) {
    throw response;
  }

  return (await response.json()) as ActivateLicenseResponse;
}

function getHeaders() {
  return {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
}